<template>
  <div>
    <b-overlay
      :show="$apollo.loading"
      rounded="sm"
      spinner-variant="primary"
    >
      <b-card
        class="mt-2"
      >
        <b-card-title class="lead">
          Assigned Mentors
        </b-card-title>

        <div class="custom-search d-flex justify-content-between">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                class="d-inline-block mr-1"
                placeholder="Search"
                type="text"
              />
            </div>
          </b-form-group>

        </div>
        <!-- table -->
        <vue-good-table
          :columns="columns"
          :pagination-options="{
            enabled: true,
            perPage:pageLength,
            position: 'top',
          }"
          :rows="rows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.label === 'Name'">
              {{ props.formattedRow[props.column.field] }}
              <b-link
                v-b-tooltip:hover="'View Details'"
                class="ml-50"
                size="sm"
                variant="flat-primary"
                @click="$showEntityModal(props.row.id)"
              >
                <feather-icon icon="InfoIcon" />
              </b-link>
            </span>

            <!-- Column: Action -->
            <span
              v-else-if="props.column.field === 'action'"
              class="d-flex"
            >
              <b-button
                v-if="$route.name === 'startup-programs'"
                v-b-tooltip="'Manage Workshops'"
                class="mr-50"
                size="sm"
                variant="outline-primary"
                :to="{ name: 'startup-programs', params: { id: $route.params.id, aid: rows[props.row.originalIndex].id }, hash:'#Workshops' }"
              >
                Workshop
              </b-button>
              <b-button
                v-else
                v-b-tooltip="'Manage Workshops'"
                class="mr-50"
                size="sm"
                variant="outline-primary"
                :to="{ name: 'startup-events', params: { id: $route.params.id, aid: rows[props.row.originalIndex].id }, hash:'#Workshops' }"
              >
                Workshop
              </b-button>
              <b-button
                v-if="$route.name === 'startup-programs'"
                v-b-tooltip="'Manage Masterclass'"
                class="mr-50"
                size="sm"
                variant="outline-primary"
                :to="{ name: 'startup-programs', params: { id: $route.params.id, aid: rows[props.row.originalIndex].id }, hash:'#Masterclass' }"
              >
                Masterclass
              </b-button>
              <b-button
                v-else
                v-b-tooltip="'Manage Masterclass'"
                class="mr-50"
                size="sm"
                variant="outline-primary"
                :to="{ name: 'startup-events', params: { id: $route.params.id, aid: rows[props.row.originalIndex].id }, hash:'#Masterclass' }"
              >
                Masterclass
              </b-button>

              <b-button
                v-if="$route.name === 'startup-programs'"
                v-b-tooltip="'Manage Sessions'"
                class="mr-50"
                size="sm"
                variant="outline-primary"
                :to="{ name: 'startup-programs', params: { id: $route.params.id, aid: rows[props.row.originalIndex].id }, hash:'#Sessions' }"
              >
                Sessions
              </b-button>
              <b-button
                v-else
                v-b-tooltip="'Manage Sessions'"
                class="mr-50"
                size="sm"
                variant="outline-primary"
                :to="{ name: 'startup-events', params: { id: $route.params.id, aid: rows[props.row.originalIndex].id }, hash:'#Sessions' }"
              >
                Sessions
              </b-button>
            </span>

            <span v-else-if="props.column.field === 'status'">
              <b-badge
                v-if="props.row.users_customuser.programs_partnerstables.length>0"
                :variant="props.row.users_customuser.programs_partnerstables[0].is_active || props.row.status.toLowerCase() != 'accept' ?statusVariant(props.row.status.toLowerCase()):'light-danger'"
                class="text-capitalize mr-1"
              >
                {{
                  props.row.status.toLowerCase() === 'accept' ? props.row.users_customuser.programs_partnerstables[0].is_active ? 'Active' : 'Inactive' : props.row.status
                }}</b-badge>
            </span>
            <!--            badge added to assigned and super mentor -->
            <span v-else-if="props.column.field === 'type'">
              <span v-if="props.row.is_super && props.row.programs_startupassignmenttables.length > 0">
                <b-badge
                  variant="primary"
                >
                  Assigned
                </b-badge>
                <b-badge
                  class="ml-1"
                  variant="primary"
                >
                  Super mentor
                </b-badge>
              </span>
              <span v-else-if="props.row.is_super">
                <b-badge
                  variant="primary"
                >
                  Super mentor
                </b-badge>
              </span>
              <span v-else>
                <b-badge
                  variant="primary"
                >
                  Assigned
                </b-badge>
              </span>
            </span>
            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <!-- pagination -->
          <template
            slot="pagination-top"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mb-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10','20','30']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of <span class="font-medium-4 ml-25"><b-badge
                  variant="light-primary"
                >{{
                  props.total
                }} entries
                </b-badge></span></span>

              </div>
              <div>
                <b-pagination
                  :per-page="pageLength"
                  :total-rows="props.total"
                  :value="1"
                  align="right"
                  class="mt-50"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </b-overlay>
    <b-modal
      id="deleteModal"
      ok-title="Yes, Delete"
      ok-variant="danger"
      button-size="sm"
      no-close-on-backdrop
      centered="true"
      size="sm"
      title="Confirm Delete"
      @ok="deleteMentor()"
    >
      <h6>Are you sure you want to delete this mentor?</h6>
    </b-modal>
    <b-modal
      id="assignStartup"
      ok-only
      ok-title="Assign"
      title="Assign Startup"
      no-close-on-backdrop
      @ok="assignStartup"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Select Startups"
            label-for="selectStartups"
          >
            <v-select
              id="selectStartups"
              v-model="startupParticipant"
              :get-option-label="option => option.programs_applicantstable.users_organizationtable.title"
              :options="startups"
              :reduce="option => option.id"
              placeholder="Select Startups"
            />
            <vue-good-table
              :columns="columns2"
              :pagination-options="{
                enabled: true,
                perPage:pageLength,
                position: 'top',
              }"
              :rows="startups2"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm }"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.formattedRow[props.column.field].field === 'action'">
                  <b-button
                    variant="danger"
                    size="sm"
                  >Deactivate
                  </b-button>
                </span>

                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
              <!-- pagination -->
              <template
                slot="pagination-top"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mb-1">
                    <span class="text-nowrap ">
                      Showing 1 to
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['10','20','30']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap"> of <span class="font-medium-4 ml-25"><b-badge
                      variant="light-primary"
                    >{{
                      props.total
                    }} entries
                    </b-badge></span></span>

                  </div>
                  <div>
                    <b-pagination
                      :per-page="pageLength"
                      :total-rows="props.total"
                      :value="1"
                      align="right"
                      class="mt-50"
                      first-number
                      last-number
                      next-class="next-item"
                      prev-class="prev-item"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>

import {
  BBadge,
  BButton,
  BCard,
  BCardTitle,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect, BLink,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import gql from 'graphql-tag'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BLink,
    BButton,
    BFormInput,
    BCard,
    BCardTitle,
    BCol,
    BFormGroup,
    BRow,
    BBadge,
    VueGoodTable,
    vSelect,
    BPagination,
    BFormSelect,
  },
  data() {
    return {
      mutationLoading: false,
      pageLength: 10,
      startupParticipant: null,
      options: [],
      mentor: {
        id: null,
        email: null,
        designation: null,
      },
      searchData: null,
      selectedfid: null,
      searchTerm: '',
      selectedRow: 10,
      rows: [],
      startups: [],
      startups2: [],
      columns: [
        {
          label: 'Name',
          field(row) {
            if (!row.users_customuser) return '-'
            return row.users_customuser.full_name || '-'
          },
        },
        {
          label: 'Type',
          field: 'type',
        },
        { label: 'Action', field: 'action' },

      ],
      columns2: [

        {
          label: 'Name',
          field(row) {
            if (!row.programs_startupparticipant.programs_applicantstable.users_organizationtable.title) return '-'
            return row.programs_startupparticipant.programs_applicantstable.users_organizationtable.title || '-'
          },
        },
        {
          label: 'Action',
          field(row) {
            if (!row.programs_startupparticipant.programs_applicantstable.users_organizationtable.title) return ''
            return ({
              field: 'action',
              id: row.id,
              programs_startupparticipant: row.programs_startupparticipant,
            })
          },
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        accept: 'light-success',
        invited: 'light-primary',
        reject: 'light-danger',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
    existingUser() {
      return this.rows.findIndex(e => e.user_id === this.mentor.id) !== -1
    },
  },
  methods: {
    fetchUser() {
      if (!this.mentor.email) return false
      this.mutationLoading = true
      this.$apollo.query({
        query: gql`query options($q: String!){
              users_customuser(where: {email: {_ilike: $q}}) {
                      full_name
                      email
                      id
                    }
              }`,
        variables: { q: this.mentor.email },
      })
        .then(({ data }) => {
          this.mutationLoading = false
          this.searchData = !data.users_customuser[0] ? false : data.users_customuser[0].full_name
          this.mentor.id = data.users_customuser[0]?.id || null
        })
      return true
    },
    getStartups() {
      this.$apollo.queries.startups.refetch()
      this.$apollo.queries.startups2.refetch()
    },
    assignStartup() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
        mutation {
          insert_programs_startupassignmenttable_one(object: {participant_id: "${this.startupParticipant}", partner_id: "${this.selectedRow}"}) {
            id
          }
        }`,
        update: (store, { data: { insert_programs_startupassignmenttable_one } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: insert_programs_startupassignmenttable_one.id ? 'Added successfully' : 'Failed to add',
              icon: insert_programs_startupassignmenttable_one.id ? 'CheckIcon' : 'XIcon',
              variant: insert_programs_startupassignmenttable_one.id ? 'success' : 'warning',
            },
          })
          this.$apollo.queries.startups.refetch()
          this.$apollo.queries.startups2.refetch()
          this.mutationLoading = false
        },
      })
    },
    addMentor() {
      this.$apollo.mutate({
        mutation: gql`
          mutation {
            insert_users_associationtable_one(object: {program_id: ${this.$route.params.id}, role: "${this.mentor.designation}", status: "Invited", user_id: ${this.mentor.id}}) {
              id
            }
          }`,
        update: () => {
          this.mentor.id = null
          this.mentor.designation = null
          this.$apollo.queries.rows.refetch()
        },
      })
    },
    updateStatus(status, item, col) {
      const update = status ? 'false' : 'true'
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation {
          update_programs_partnerstable_by_pk(pk_columns: {id: ${item}}, _set: {${col}: ${update}}) {
            id
          }
        }`,
        update: () => {
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
    deleteMentor() {
      const item = this.selectedRow
      const association = this.selectedfid
      this.mutationLoading = true
      if (item) {
        this.$apollo.mutate({
          mutation: gql`mutation {
            update_programs_partnerstable_by_pk(pk_columns: {id: ${item}}, _set: {is_deleted: true}) {
              id
            }
          }`,
          update: () => {
            this.mutationLoading = false
          },
        })
      }
      this.$apollo.mutate({
        mutation: gql`mutation {
          update_users_associationtable_by_pk(pk_columns: {id: ${association}}, _set: {is_deleted: true}) {
            id
          }
        }`,
        update: () => {
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
        {
          programs_partnerstable(where: {_or: {is_super: {_eq: true}, programs_startupassignmenttables: {participant_id: {_eq: ${this.$route.params.aid}}}}, program_id: {_eq: ${this.$route.params.id}}}) {
          is_super
          id
          users_customuser {
            full_name
          }
          programs_startupassignmenttables {
            id
            status
          }
        }
        }`
      },
      update: data => data.programs_partnerstable,
    },
    startups: {
      query() {
        return gql`{
          programs_startupparticipants(where: {programs_applicantstable: {program_id: {_eq: ${this.$route.params.id}}}, is_active: {_eq: true}, programs_startupassignmenttables_aggregate: {count: {predicate: {_eq: 0}, filter: {partner_id: {_eq: ${this.selectedRow}}}}}}) {
            id
            programs_applicantstable {
              users_organizationtable {
                title
              }
            }
          }
        }`
      },
      update: data => data.programs_startupparticipants,
    },
    startups2: {
      query() {
        return gql`{
          programs_startupassignmenttable(where: {partner_id: {_eq: ${this.selectedRow}}}) {
            participant_id
            partner_id
            programs_startupparticipant {
              programs_applicantstable {
                users_organizationtable {
                  title
                }
              }
            }
          }
        }`
      },
      update: data => data.programs_startupassignmenttable,
    },
  },
}
</script>
